<template>
  <div class="mainpage home">
    <div class="container h-100">
      <p>If you are new to solving mystery caches or you want hints, read the <router-link to="/howtostart">How to's</router-link>.</p>
      <h3>Coordinates</h3>
      <div class="p mb-2">
        <router-link to="/coord/coordconvert">Converter</router-link> converts (batches) of coordinates.<br>
        <router-link to="/coord/coordproj">Project a coordinate</router-link> supports meter, mile, yard and more.<br>
        <router-link to="/coord/coordlines">Lines</router-link> midpoint, distance, direction, intersection, closest to point.<br>
        <router-link to="/coord/coordtriangles">Triangles</router-link> centroid, orthocenter, incenter, circumcenter, surface and more.<br>
        <router-link to="/coord/coordmapmaker">Map maker</router-link> show coordinates with marker and circle to help your carotte attempts.<br>
        <router-link to="/coord/coordincomplete">Incomplete coordinates</router-link> plot the possible coordinates on the map.<br>
        <router-link to="/coord/coordantipode">Antipodes</router-link> are the points on the other side of the earth.<br>
        Supports  WGS84, UTM, MGRS, Dutch grid (RD), NAD, Belgian Lambert 2008, RGF 93, Geohash, Open Location Code and Maidenhead Locator.
      </div>
      <h3>Text tools</h3>
      <div class="p mb-2">
        <router-link to="/texttoolbox/textwordvalue">Word value or square root</router-link>. Many alphabets, scrabble and vanity code.<br>
        <router-link to="/texttoolbox/textcodes">Character &amp; codes</router-link> UTF-16, ASCII, alt codes and more.<br>
        <router-link to="/texttoolbox/smartreplace">Smart replace</router-link> use frequency analysis to crack codes.<br>
        <router-link to="/texttoolbox/textanalyzer">Analyzer</router-link> counts words, lines and a lot more.<br>
        <router-link to="/texttoolbox/texttonumbers">Text to numbers</router-link> and numbers to text.<br>
        <router-link to="/texttoolbox/textkeyboards">Keyboards</router-link> support Dvorak, Cyrillic, AZERTY and a few more.
      </div>
      <h3>Ciphers &amp; codes</h3>
      <div class="p mb-2">
        Plenty of ciphers &amp; codes to crack encrypted messages. Try
        <router-link :to="{ name: 'Cipher', params: { cipher: 'ROT13'}}">ROT13</router-link> or try
        <router-link :to="{ name: 'Cipher', params: { cipher: 'ROT47'}}">ROT47</router-link> or try the
        <router-link :to="{ name: 'Cipher', params: { cipher: 'Vigenere'}}">Vigenere</router-link> cipher.
        Or just simply <router-link :to="{ name: 'Cipher', params: { cipher: 'Reverse'}}">reverse</router-link> a message.
        Ever heard of the <router-link :to="{ name: 'Cipher', params: { cipher: 'Kenny code'}}">Kenny code</router-link>?
        Or want to translate <router-link :to="{ name: 'Cipher', params: { cipher: 'Morse'}}">Morse</router-link>?
        There are 49 different ciphers. You can quickly try different ciphers and keywords.
        Supports binary encodings, such as <router-link :to="{ name: 'Cipher', params: { cipher: 'Base91'}}">Base91</router-link>,
        <router-link :to="{ name: 'Cipher', params: { cipher: 'Base16'}}">Base16</router-link>,
        <router-link :to="{ name: 'Cipher', params: { cipher: 'Base32'}}">Base32</router-link>,
        <router-link :to="{ name: 'Cipher', params: { cipher: 'Base64'}}">Base64</router-link> and
        <router-link :to="{ name: 'Cipher', params: { cipher: 'UUencode'}}">UUencode</router-link>. It can also convert
        <router-link :to="{ name: 'Cipher', params: { cipher: 'GCId'}}">GC codes to GC id's</router-link>.
      </div>
      <h3>Codebook</h3>
      <div class="p mb-2">
        <router-link to="/codebook">Code book</router-link> find codes using keywords and browse them.
      </div>
      <h3>Image tools</h3>
      <div class="p mb-2">
        <router-link to="/imagetools">Image tools</router-link> will read the file metadata (EXIF and some other) and display them. If there is a GPS location in the file it will show
        it on the map. You can flip and rotate the image as well.
      </div>
      <h3>HTML Scanner</h3>
      <div class="p mb-2">
        <router-link to="/htmltools">HTML scanner</router-link> copy paste the page source of the geocache and the scanner will find all the links, images, hidden text and more.
      </div>
      <h3>Math tools</h3>
      <div class="p mb-2">
        <router-link to="/math/baseconvertor">Base converter</router-link> converts any base between 2 and 36.<br>
        <router-link to="/math/countdigits">Decimals of</router-link> &#x1D70B;, <i>e</i>, &#x1D70F;, &phi;, &gamma; &radic;2 and &radic;3 (maximum 1,000,000 decimals).<br>
        <router-link to="/math/fibonacci">Fibonacci</router-link> check the first 500 Fibonacci numbers.<br>
        <router-link to="/math/gcdandlcm">Prime factors, GCD and LCM</router-link> of one or more numbers.<br>
        <router-link to="/math/numberproperties">Number properties</router-link> tells you the properties of a number.<br>
        <router-link to="/math/nimbers">Nimbers</router-link> are not numbers, but you can add and multiply them.<br>
        <router-link to="/math/palindromecounter">Palindrome</router-link> iteration counter.<br>
        <router-link to="/math/sequences">Sequences</router-link> find the number in a sequence.<br>
      </div>
      <h3>Sudoku Solver</h3>
      <div class="p mb-2">
        <router-link to="/sudokusolver">Sudoku and SudokuX solver</router-link>. If there are multiple solutions you can pick one from the dropdown to be displayed. Supports 2x2 and 3x3. Sudoku 4x4 disabled because of server load.<br><br>
      </div>
    </div>
  </div>
</template>
